const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  sizes: {
    container: 1040,
  },
  fonts: {
    heading: "'Press Start 2P', sans-serif",
    body: "'Ubuntu', sans-serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64
  ],
  colors: {
    text: "#fff",
    background: '#1D4259',
    primary: '#D9896C',
    primaryLight: '#F07C6C',
    secondary: 'rgba(3, 150, 166, 1)',
  },
  buttons: {
    primary: {
      color: 'background',
      bg: 'text',
      fontFamily: 'heading',
      fontWeight: 700,
      borderRadius: 0,
      boxShadow: `6px 6px 0px 0px rgba(240, 240, 240, 0.1)`,
      textTransform: 'uppercase',
      '&:hover': {
        boxShadow: `6px 6px 0px 0px rgba(240, 240, 240, 0.2)`,
      }
    },
    secondary: {
      boxShadow: `6px 6px 0px 0px rgba(240, 240, 240, 0.1)`,
    },
    disabled: {
      bg: 'rgba(240, 240, 240, 1)',
      color: '#222'
    }
  },
  cards: {
    primary: {
      backgroundColor: 'white',
      boxShadow: `10px 10px 0px 0px rgba(3, 150, 166, 1)`,
      padding: 3,
      marginTop: 4,
      color: 'black'
    }
  }
};

// Key Sizes
theme.space.tiny = theme.space[1]
theme.space.small = theme.space[2]
theme.space.medium = theme.space[3]
theme.space.large = theme.space[4]

export default theme;
