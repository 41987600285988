import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/analytics';

import { ThemeProvider, BaseStyles } from 'theme-ui'
import theme from './theme'

import { FirebaseProvider } from './context-firebase'

import { firebaseConfig } from './firebase'

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <FirebaseProvider value={firebase}>
      <ThemeProvider theme={theme}>
          <BaseStyles>
            <App />
          </BaseStyles>
      </ThemeProvider>
    </FirebaseProvider>
  </React.StrictMode>,
  document.getElementById('root')
);