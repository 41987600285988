import React, { useContext, useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import { Container, Button, Input, Spinner, Card } from "theme-ui";

import "./App.css";

import FirebaseContext from "./context-firebase";

function Redirecter({ facilitate = false }) {
  const firebase = useContext(FirebaseContext);
  let { gameKey } = useParams();
  const [searching, setSearching] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const lookup = async (code) => {
    setSearching(true);
    setNotFound(false)
    const ref = firebase.firestore().collection("sprints");
    const games = await ref.where("gameKey", "==", code).limit(1).get();
    if (!games.empty) {
      games.forEach(
        (doc) =>
          (window.location = `https://www.learningsprint.club/play/${doc.id}${
            facilitate ? "/facilitate" : ""
          }`)
      );
    } else {
      setSearching(false);
      setNotFound(true);
    }
  };
  useEffect(() => {
    lookup(gameKey);
  }, [gameKey]);

  return (
    <Container>
      <Card>
      { searching && <Spinner />}
      {!notFound && <p>Redirecting you to {gameKey}</p>}
      {notFound && (
        <>
          <div
            style={{
              border: "1px solid #cd3f39",
              background: "#d9453f",
              color: "white",
              margin: 10,
              padding: 10,
            }}
          >
            Not found, try again
          </div>
          <p>
            <Link to="/">
              Try another code
            </Link>
          </p>
        </>
      )}
      </Card>
    </Container>
  );
}

function App() {
  const firebase = useContext(FirebaseContext);
  const [value, setValue] = useState("");
  const [searching, setSearching] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const lookup = async (code) => {
    setSearching(true);
    setNotFound(false);
    const ref = firebase.firestore().collection("sprints");
    const games = await ref.where("gameKey", "==", code).limit(1).get();
    if (!games.empty) {
      setSearching(false);
      games.forEach(
        (doc) =>
          (window.location = `https://www.learningsprint.club/play/${doc.id}`)
      );
    } else {
      setSearching(false);
      setNotFound(true);
    }
  };
  return (
    <Router>
      <Switch>
        <Route exact path="/:gameKey/facilitate">
          <Redirecter facilitate={true} />
        </Route>
        <Route exact path="/:gameKey">
          <Redirecter />
        </Route>
        <Route path="/">
          <Container>
            <Card>
            <h1>Learning Sprint</h1>
            <p>Type in your game code, and hit "Go" to play.</p>
            { searching && <Spinner />}
            <Input
              style={{ marginBottom: 10 }}
              type="text"
              onChange={(e) => setValue(e.target.value.toUpperCase())}
              value={value}
            />
            {notFound && (
              <div
                style={{
                  border: "1px solid #cd3f39",
                  borderRadius: 3,
                  background: "#d9453f",
                  color: "white",
                  margin: 10,
                  padding: 10,
                }}
              >
                Not found, try again
              </div>
            )}
            <Button
              type="button"
              onClick={() => lookup(value)}
              disabled={searching}
            >
              Go
            </Button>
            </Card>
          </Container>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
